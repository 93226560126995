<template>
	<div class="productTab">
		<div class="tabTitle sticky-top">
			<div
				v-for="(item, index) in tabTitleData"
				:key="index"
				@click="tabCli(index)"
				class="titleCont"
				:class="tabActiveInd == index ? 'tabActiveTitle' : ''"
			>
				<span>
					{{ item }}
				</span>
			</div>
		</div>
		<div class="tabContList">
			<!-- 日历事件 -->
			<div class="contItem">
				<CalendarEvents />
				<CalendarEvents2 />
			</div>
			<!-- 人脉 -->
			<div class="contItem">
				<Connection />
				<Connection2 />
				<Connection3 />
				<Connection4 />
			</div>
			<!-- 待办 -->
			<div class="contItem">
				<Backlog />
			</div>
			<!-- 纪要 -->
			<div class="contItem">
				<Summary />
			</div>
			<!-- 特色功能 -->
			<div class="contItem">
				<div class="offline row">
					<div class="img col-4">
						<img
							src="../assets/imgs/product/offline_img.png"
							alt=""
						/>
					</div>
					<div class="text col-5">
						<h2>随时随地，离线无忧</h2>
						<p>
							无论在办公室还是飞机上，都可以随时访问和修改，<br
								class="show"
							/>WOLB确保让您的管理保持井然有序，持续高效。
						</p>
					</div>
				</div>
				<div class="multidevice row">
					<div class="text hiden col-4">
						<h2>多端登录</h2>
						<p>
							当您还在为反复切换设备痛苦时，WOLB已经满足了您。无论是移动设备还是iPad设备，都可以同时使用，并且自动同步数据，不需要再重新编辑。
						</p>
					</div>
					<div class="img col-4">
						<img
							src="../assets/imgs/product/multidevice_img.png"
							alt=""
						/>
					</div>
					<div class="text show col-4">
						<h2>多端登录</h2>
						<p>
							当您还在为反复切换设备痛苦时，WOLB已经满足了您。<br
								class="show"
							/>无论是移动设备还是iPad设备，都可以同时使用，<br
								class="show"
							/>并且自动同步数据，不需要再重新编辑。
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { ref } from "vue";
import CalendarEvents from "./tabCont/CalendarEvents.vue";
import CalendarEvents2 from "./tabCont/CalendarEvents2.vue";
import Connection from "./tabCont/Connection.vue";
import Connection2 from "./tabCont/Connection2.vue";
import Connection3 from "./tabCont/Connection3.vue";
import Connection4 from "./tabCont/Connection4.vue";
import Backlog from "./tabCont/Backlog.vue";
import Summary from "./tabCont/Summary.vue";
export default {
	name: "ProductTab",
	components: {
		CalendarEvents,
		CalendarEvents2,
		Connection,
		Connection2,
		Connection3,
		Connection4,
		Backlog,
		Summary
	},
	setup() {
		const tabTitleData = ["日历事件", "人脉", "待办", "纪要", "特色功能"];
		const tabActiveInd = ref(0);
		const tabCli = (data) => {
			tabActiveInd.value = data;
			autoTop();
			clearInterval(tabTimer);
		};
		const tabTimer = setInterval(() => {
			if (tabActiveInd.value >= 4) {
				tabActiveInd.value = 0;
			} else {
				tabActiveInd.value = ++tabActiveInd.value;
			}
		}, 5000);
		const autoTop = () => {
			let scrollHeight =
				$(".topImg").height() + $(".productsIntroduction").height();
			let mianScrollHeight = $(".main").scrollTop();
			console.log(scrollHeight, mianScrollHeight);
			// if (mianScrollHeight >= scrollHeight) {
			$(".main").scrollTop(scrollHeight);
			// }
		};
		return {
			tabTitleData,
			tabCli,
			tabActiveInd,
			autoTop,
			// tabTimer,
		};
	},
	methods: {},
	mounted() {},
	watch: {
		tabActiveInd: (data) => {
			$(".contItem").eq(data).show().siblings().hide();
		},
	},
};
</script>
<style lang="scss" scoped>
.productTab {
	.tabTitle {
		display: flex;
		border-bottom: 1px solid #eff3ff;
		justify-content: center;
		background-color: #fff;
		border-top: 5px solid #fff;
		top: 110px;
		z-index: 1111;
		box-sizing: border-box;
		transform: translateY(-5px);
		.titleCont {
			width: 219px;
			height: 70px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #1d2129;
			font-size: 20px;
			cursor: pointer;
		}
		.tabActiveTitle {
			color: #5579fd;
			span {
				position: relative;
				display: flex;
				align-items: center;
				padding: 22px 0;
				height: 100%;
				min-width: 80px;
				text-align: center;
				justify-content: center;
				&::after {
					content: "";
					position: absolute;
					top: 100%;
					left: 50%;
					margin-top: -3px;
					transform: translateX(-50%);
					display: inline-block;
					width: 100%;
					height: 4px;
					background-color: #5579fd;
				}
			}
		}
	}
	.tabContList {
		margin-bottom: 50px;
	}
	.tabContList .row {
		justify-content: center;
		padding-top: 74px;
		margin: 0;
		.img {
			text-align: center;
			img {
				height: 457px;
				max-width: 100%;
				max-height: 100%;
			}
		}

		p {
			letter-spacing: 3px;
		}
		.text {
			margin-left: 67px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-right: 50px;
		}
		h2 {
			font-size: 46px;
			color: #192646;
			font-weight: 400;
			margin-bottom: 58px;
		}
		h3 {
			color: #8c919c;
			font-size: 27px;
			font-weight: normal;
			margin-top: 90px;
			margin-bottom: 40px;
		}
		p {
			color: #192646;
			font-size: 20px;
			line-height: 1.5;
			width: 620px;
		}
		p:nth-of-type(1) {
			// margin-bottom: 20px;
			margin-bottom: 78px;
		}
		.cont {
			display: inline-flex;
			align-items: center;
			height: 67px;
			border-radius: 34px;
			overflow: hidden;
			background-color: rgba(201, 209, 216, 0.24);
			flex-shrink: 0;
			flex-grow: 0;
			width: auto;
			margin-top: 60px;
			span {
				color: #6e3fc3;
				font-weight: 500;
				font-size: 12px;
			}
			span:first-child {
				padding: 0 41px;
			}
			span:nth-child(2) {
				padding: 0 31px;
				border-left: 1px solid rgba(201, 209, 216, 0.43);
			}
			span:nth-child(3) {
				padding-left: 31px;
				padding-right: 51px;
				border-left: 1px solid rgba(201, 209, 216, 0.43);
			}
		}
	}
	.contItem:not(:first-child) {
		display: none;
	}
	.tabContList .offline {
		margin-top: 60px;
		.img {
			margin-left: 40px;
			img {
				height: 487px;
			}
		}
		.text {
			margin-left: 40px;
			justify-content: start;
		}
		p:nth-of-type(1) {
			width: 700px;
		}
	}
	.tabContList .multidevice {
		padding-bottom: 20px;
		.img {
			margin-left: 40px;
			img {
				height: 483px;
			}
		}
		.text {
			margin-left: 40px;
			justify-content: start;
		}
		p {
			width: 584px;
		}
	}
}
@media screen and (max-width: 720px) {
	.productTab .tabContList .multidevice .img {
		margin-left: -120px;
	}
}

@media screen and (max-width: 600px) {
	.productTab .tabContList {
		margin-bottom: 0px;
		.contactsTemplate,
		.offline,
		.multidevice {
			.img,
			.text,
			p {
				width: 100% !important;
				margin: 0;
				padding: 0;
				text-align: center;
			}
			.img,
			.img img {
				width: 462px;
				height: 684px !important;
				max-width: auto !important;
				max-height: auto !important;
			}
			.img {
				margin-bottom: 20px;
			}
			h2 {
				font-size: 49px;
				color: #192646;
				letter-spacing: 1px;
				font-weight: 500;
				margin-top: 30px;
				line-height: 74px;
				margin-bottom: 30px;
			}
			.text p {
				font-size: 33px;
				color: #192646;
				line-height: 59px;
				letter-spacing: 3px;
			}
		}
	}
	.productTab {
		.tabTitle {
			height: 128px;
			border: none;
			background-color: #f9faff;
			.titleCont {
				width: 179px;
				height: 128px;
				span {
					font-size: 38px;
				}
			}
			.tabActiveTitle span::after {
				content: none;
			}
		}
	}
	.productTab .tabContList {
		.offline,
		.multidevice {
			.img,
			.img img {
				width: 745px;
				height: auto !important;
			}
		}
	}
}
</style>
